export default {
    enable: "Ativar",
    disable: "Desativar",
    name: "Nome",
    lastName: "Apelido",
    contactName: "Nome de contato",
    commercialName: "Nome comercial",
    phone: "Telemóvel",
    population: "Ppulação",
    province: "Distrito",
    hour: "Hora ",
    save: "Guardar",
    delete: "Eliminar",
    observations: "Observações",
    country: "País",
    postalCode: "Código Postal",
    client: "Cliente",
    passport: "N.I.F Passaporte",
    email: "E-mail",
    suscribe: "subscrever",
    message: "mensagem",
    loading: "Carregando",
    update: "Atualizar",
    date: "Data",
    invalidOrderFields: "Há campos inválidos no seu pedido",
    brands: "Marcas",
    total: "Total",
    showAll: "Monstrar todo",
    invoice: "Fatura ",
    identifier: "Identificador",
    description: "Descrição",
    keepShoping: "Continuar a comprar",
    goCheckout: "Finalizar compra",
    product: "Produto",
    products: "Produtos",
    category: "Categoria",
    categories: "Categorias",
    productCategories: "Categorias de produtos",
    barCode: "Código de barras",
    barCodeHeader: "Cod. Barras",
    price: "Preço",
    password: "Palavra-chave",
    recoverPassword: "Recuperar palavra-chave",
    quantity: "Quantidade",
    stock: "Estoque",
    action: "Ação",
    search: "Pesquisar",
    findByDate: "Filtrar por data",
    searching: "Pesquisando",
    address: "Morada",
    addProduct: "Adicionar produto",
    addresses: "Moradas",
    activate: "Ativar",
    deactivate: "Desativar",
    close: "Fechar",
    warning: "Aviso",
    create: "Criar",
    back: "Voltar",
    next: "Seguinte",
    continue: "Continuar",
    download: "Descarregar",
    reference: "Referência",
    referenceHeader: "Ref.",
    welcome: "Bem-vindo",
    cancel: "Cancelar",
    accept: "Aceitar",
    send: "Enviar",
    end: "Finalizar",
    notAvaliableServiceHeader: "Lamentamos",
    notAvaliableServiceBody: "Este serviço ainda não está disponível",
    noResults: "Sem resultados",
    emptyLabel: "Por favor preencher este campo",
    invalidPostalCode: "código postal inválido",
    minimumLength: "O campo deve conter o minimo .... carateres",
    maximusLength: "O campo deve conter o maximo .... carateres",
    characters: "Carateres",
    clone: "Clonar",
    invalidEmail: "E-mail inválido",
    requiredEmail: "E-mail necesário",
    requiredInput: "Campo obrigatório",
    requiredPassword: "Palavra-passe obrigatória",
    invalidDyn_1: "O campo",
    invalidDyn_2: "É Inválido",
    minimunSearch_1: "A sua pesquisa deve conter no minimo 3 letras",
    minimunSearch_2: "Letras",
    notMatchingPasswords: "A palavra-chave introduzida não coincide",
    deleteAddressConfirmation: "Têm a certeza que quer eliminar essa morada?",
    createAddressConfirmation: "Têm a certeza que quer criar essa morada?",
    activateClientConfirmation: "Quer ativar este utilizador?",
    deactivateClientConfirmation: "Deseja desativar este utilizador?",
    createClientConfirmation: "Têm a certeza que quer criar o cliente?",
    clearCartConfirmation: "Têm a certeza que quer limpar o carrinho",
    orderConfirmation: "Deseja efetuar o pedido ",
    patchOrderConfirmation: "Têm a certeza que quer editar o seu pedido,.As alterações serão irreversíveis após o envio do pedido.",
    deleteOrderConfirmation: "Se continuar, o seu pedido será eliminado",
    updatePasswordConfirmation: "Têm a certeza que quer atualizar a sua palavra-chave?",
    politicConfirmation: "Eu li e aceito a política de privacidade",
    home: "Inicio",
    novelties: "Novidades",
    videos: "Vídeos",
    contact: "Contato",
    company: "Empresa",
    personalData: "Dados pessoais",
    order: "Pedido",
    orders: "Pedidos",
    createOrder: "Criar pedido",
    invoices: "Faturas",
    promo: "Promoções",
    notifications: "Notificações",
    favourites: "Favoritos",
    clients: "Clientes",
    login: "Iniciar sessão",
    logout: "Cerrar a sessão",
    nothing: "Nenhuma",
    nothingToShow: "Nada a mostrar",
    myProfile: "O meu perfil",
    replacePassword: "Substituir a minha palavra-chave",
    replacePasswordLabel_1: "Palavra- chave atual",
    replacePasswordLabel_2: "Nova palavra-chave",
    replacePasswordLabel_3: "Repetir a palavra- chave",
    advertisement: "Subscreve-se a nossa newsletter e fique por dentro de todas as nossas novidades e lançamentos.",
    advertisementCheckBox: "Li e aceito a politica",
    inProgressStatus: "Em curso",
    waitingStatus: "Em espera",
    sendStatus: "Enviado",
    finishStatus: "Finalizado",
    draftStatus: "Apagador",
    canceledStatus: "Cancelado",
    retrasedStatus: "Atrasado",
    orderRefecence: "Referência do pedido",
    orderReferenceHeader: "Ref. pedido",
    buyDateAtHeader: "Data de compra",
    stateHeader: "Estado",
    invoiceNumber: "Numero fatura",
    invoiceNumberHeader: "Nº Fatura",
    notFoundInvoices: "Não se encontraram faturas",
    newClient: "Cliente novo",
    createClient: "Criar cliente",
    deactivatedClients: "Cliente desativado",
    noAvaliableClients: "Não existe clientes disponíveis",
    clientInfo: "Informação do cliente",
    clientFile: "Ficha do cliente",
    createAddress: "Criar morada de envio",
    newAddress: "Nova morada",
    newDeliveryAddress: "Nova morada de envio",
    sendAddress: "Morada de envio",
    logoutWarning: "Se continuar, a sua sessão finalizará",
    searchTip_1: "Insatisfeito com os resultados?",
    searchTip_2: "Pesquisar uma palavra-chave nova",
    notFoundProducts: "Não se encontraram produtos",
    estimatedStockDate: "Data aproximada da disponibilidade",
    boxQuantity: "Unidades por caixa",
    minimumBuyQuantity: "Unidades mínimas de venda",
    downloadFileProduct: "Descarregar a ficha do produto",
    noAvaliableProducts: "Não há produtos disponíveis",
    expandCart: "Pagar",
    emptyCartMessage: "Não a produtos no carrinho",
    footerLeftInfo: "Dúvidas? A equipa Perfect Beauty está aqui para ajudar",
    footerRightInfo: "Se tiver alguma dúvida ou consulta técnica sobre algum dos nossos produtos, escreve-nos e o nosso técnico ou comercial entrará em contato consigo",
    legalWarn: "Aviso legal",
    privacityPolitics: "Política de privacidade",
    cookiesPolitics: "Política de cookies",
    checkout: "Dar curso ao pedido",
    deliveryAddress: "Morada de entrega",
    orderSummary: "Resumo de pedido",
    backCart: "Voltar ao carrinho",
    noAvaliableOrders: "Nenhum pedido disponível",
    taxBase: "Tributável",
    taxBaseHeader: "B.tributável",
    pvpTaxBaseHeader: "B. tributável PVP",
    commerciaRate: "PVP",
    noProductsInCart: "Deve adicionar pelo menos um produto para continuar",
    downloadHere: "Descarregar aqui",
    slogan_1: "Perfect Beauty é o sinonimo de....",
    slogan_2: "design, inovação, tecnologia e paixão",
    intro_1: "Na Perfect Beauty continuamos a evoluir e a investir para alcançar o produto da mais alta qualidade, vanguarda e tecnologia, para isso optámos pela criação de uma equipa de I&D de forma a legar o máximo de cordialidade, eficiência e operacionalidade em cada um. . das novidades que trazemos ao mercado. Outro dos pilares da Perfect Beauty é o Controlo de Qualidade, pelo qual passam cada um dos nossos produtos e peças que o compõem, proporcionando-nos maior confiabilidade para nossos clientes do que qualquer outra marca elétrica. Com o nosso Serviço Técnico asseguramos o cumprimento de todas as garantias.",
    intro_2: "Em suma, um grupo de pessoas dedicadas a responder, informar e solucionar qualquer dúvida ou sugestão dos nossos clientes.",
    newsletter: "Subscreve-te a nossa newsletter e fique por dentro de todas as nossas novidades e lançamentos.",
    watchMap: "Ver o mapa",
    cataloguePDF: "Catálogo PDF",
    companyIntro_1: "Produtos utilizados por profissionais dos salões de beleza e cabeleireiros mais destacados. Continuamos a expandir as possibilidades e a abrir horizontes para os profissionais de cabeleireiro e beleza. Sempre atualizado, com o que há de mais moderno e inovador para atender qualquer necessidade do setor.",
    companyIntro_2: "Perfect Beauty SL, uma empresa fundada por três profissionais com mais de 30 anos de experiência no setor de cabeleireiro e estética com a missão de fornecer produtos de qualidade e inovadores ao canal profissional. Uma empresa focada em fornecer o melhor serviço a um preço competitivo. Para isso contamos com uma equipa humana de 25 profissionais, distribuídos em diferentes departamentos; atendimento ao cliente, vendas, controle de qualidade, assistência técnica, I+D+I, marketing e administração, com 600 m2 de escritórios e um armazém de 7000 m2",
    companyIntro_3: "O nosso objetivo é fornecer aos profissionais uma grande variedade de produtos para facilitar o trabalho nos setores de cabeleireiro, barbearia e estética. Colocamos à disposição dos nossos clientes um catálogo composto por mais de 3.000 referências, atualizado duas vezes por ano para ser fiel à missão da nossa empresa de estar na vanguarda do setor com produtos inovadores.",
    companyIntro_4: "O controlo de qualidade como elemento diferenciador torna-nos uma referência no mercado. Graças aos recursos alocados pela empresa para o controlo do produto tanto na origem como no destino, postula-nos como empresa de garantia. O nosso serviço pós-venda se soma a esse compromisso com clientes satisfeitos. O nosso próprio departamento de pesquisa e desenvolvimento garante que sejamos inovadores em projetos, componentes e novos usos de produtos. Uma equipa dedicada a manter a marca Perfect Beauty como uma empresa dinâmica que fornece soluções ao profissional de Cabeleireiro, Barbeiro e Estética.",
    companyIntro_5: "A gestão da Perfect Beauty está consciente da importância do contributo de todos, empresas, instituições, administrações públicas e particulares, na promoção de práticas responsáveis ​​que respeitem o ambiente e que permitam um claro progresso da sociedade para um mundo melhor. razão pela qual se impôs a integração das diretrizes ambientais na sua estratégia de negócios. Essas diretrizes estão refletidas na Política Ambiental que se inspira em dois princípios: o cumprimento de todas as regulamentações ambientais aplicáveis ​​e a melhoria contínua das atividades de forma a proteger o meio ambiente. Com ambos os princípios, está comprometida com a prevenção, proteção e conservação do meio ambiente.",
    companyIntro_6: "Para realizar estes princípios, a direção da Perfect Beauty pretende: - Adotar as medidas necessárias para prevenir a poluição e, quando tal não for possível, reduzir ao mínimo as emissões poluentes. - Reduzir sistematicamente os resíduos, reciclando-os e reutilizando-os sempre que possível, bem como utilizando os recursos naturais, matérias-primas e energia de forma eficiente. - Aplicar as medidas necessárias para garantir o cumprimento das normas ambientais aplicáveis ​​às nossas atividades a nível local, nacional e global em que a empresa atua. - Divulgar a política ambiental entre colaboradores, fornecedores e demais interessados. - Formar e educar os colaboradores para que no seu dia-a-dia desempenhem o seu desempenho conforme a política ambiental. - Estabelecer procedimentos para a revisão periódica do cumprimento da política ambiental, bem como aplicar medidas corretivas quando estas tenham sido descumpridas. - Realizar um processo de melhoria contínua das nossas ações com respeito ao meio ambiente.",
    corporativeVideo: "Vídeo corporativo",
    noAddedProducts: "Não há produtos para comprar",
    favouriteAdded: "O produto foi marcado como favorito",
    username: "Nome de utilizador",
    dear: "Estimado ",
    orderSuccesfullySended: "O seu pedido foi enviado com sucesso",
    orderSendedMessageQuery_1: "Receberá por e-mail o resume do seu pedido",
    thanks: "Muito obrigada",
    commercialDepartament: "Departamento comercial da Perfect Beauty",
    seeOrders: "Ver os pedidos",
    clearFilters: "Anular os filtres",
    inStock: "Disponível no estouqe",
    noStock: "Sem estoque",
    filterByProduct: "Filtrar por produto",
    filterByDate: "Filtrar por data",
    filterByStatus: "Filtrar por estado",
    filterByClient: "Filtrar por cliente",
    fiscalName: "Nome fiscal",

    errorUnlogging: "Ocorreu um erro ao tentar encerrar a sessão.",
    successLogin: "Sessão iniciada com sucesso.",
    myCompany: "Minha Empresa",
    goHeadquarterCheckout: "Finalizar pedido na Sede",
    headquarter: "Sede",
    headquarters: "Sedes"
}
export default {
    enable: "Attivare",
    disable: "Disattivare",
    name: "Nome",
    lastName: "Cognome",
    contactName: "Nome del contatto",
    commercialName: "Nome dell'azienda",
    phone: "Telefono",
    population: "Città",
    province: "Provincia",
    hour: "ora",
    save: "Risparmiare",
    delete: "Cancellare",
    observations: "Osservazioni",
    country: "Paese",
    postalCode: "Codice postale",
    client: "Cliente",
    passport: "Codice fiscale / Passaporto",
    email: "Email",
    suscribe: "Abbonarsi",
    message: "Messaggio",
    loading: "Caricamento...",
    update: "Aggiornamento",
    date: "Data",
    invalidOrderFields: "Ci sono campi non validi nell'ordine",
    brands: "Marche",
    total: "Totale",
    showAll: "Mostra tutti",
    invoice: "Fattura",
    identifier: "Identificatore",
    description: "Descrizione",
    keepShoping: "Continua gli acquisti",
    goCheckout: "Cassa",
    product: "Prodotto",
    products: "Prodotti",
    category: "Categoria",
    categories: "Categorie",
    productCategories: "Categorie di prodotti",
    barCode: "Codici a barre",
    barCodeHeader: "Codice a barre",
    price: "Prezzo",
    password: "Password",
    recoverPassword: "Recupero password",
    quantity: "Quantità",
    stock: "Stock",
    action: "Azione",
    search: "Ricerca",
    findByDate: "Filtrare per data",
    searching: "Alla ricerca di...",
    address: "Indirizzo",
    addProduct: "Aggiungi prodotto",
    addresses: "Indirizzi",
    activate: "Attivare",
    deactivate: "Disattivare",
    close: "Chiudere",
    warning: "Avviso",
    create: "Creare",
    back: "Torna a",
    next: "Avanti",
    continue: "Continua",
    download: "Scaricare",
    reference: "Riferimento",
    referenceHeader: "Rif.",
    welcome: "Benvenuti a",
    cancel: "Annullamento",
    accept: "Accettare",
    send: "Inviare a",
    end: "Finitura",
    notAvaliableServiceHeader: "Dispiace !",
    notAvaliableServiceBody: "Questo servizio non è ancora disponibile.",
    noResults: "Non ci sono risultati.",
    emptyLabel: "Compilare questo campo",
    invalidPostalCode: "Codice postale non valido",
    minimumLength: "Il campo deve essere composto da almeno %numeroSlot% caratteri.",
    maximusLength: "Il campo deve avere un massimo di %numberSlot% caratteri.",
    characters: "Personaggi",
    clone: "Clone",
    invalidEmail: "Email non valida",
    requiredEmail: "Email richiesta",
    requiredInput: "Campo richiesto",
    requiredPassword: "È richiesta la password",
    invalidDyn_1: "Il campo",
    invalidDyn_2: "non è valido.",
    minimunSearch_1: "La ricerca deve contenere almeno 3 lettere.",
    minimunSearch_2: "lettere.",
    notMatchingPasswords: "Le password inserite non corrispondono.",
    deleteAddressConfirmation: "È sicuro di voler cancellare questo indirizzo?",
    createAddressConfirmation: "Siete sicuri di voler creare questo indirizzo?",
    activateClientConfirmation: "Si vuole attivare questo utente?",
    deactivateClientConfirmation: "Si desidera disattivare questo utente?",
    createClientConfirmation: "Siete sicuri di voler creare il cliente?",
    clearCartConfirmation: "È sicuro di voler liberare il carrello?",
    orderConfirmation: "Volete creare questo ordine?",
    patchOrderConfirmation: "Sei sicuro di voler modificare il tuo ordine? Le modifiche saranno irreversibili una volta inviato l'ordine.",
    deleteOrderConfirmation: "Se si continua, l'ordine verrà cancellato.",
    updatePasswordConfirmation: "Siete sicuri di voler aggiornare la vostra password?",
    politicConfirmation: "Ho letto e accetto l'informativa sulla privacy.",
    home: "Inizio",
    novelties: "Notizie",
    videos: "Video",
    contact: "Contatto",
    company: "Azienda",
    personalData: "Dati personali",
    order: "Ordine",
    orders: "Ordine",
    createOrder: "Crea ordine",
    invoices: "Fatture",
    promo: "Promozioni",
    notifications: "Notifiche",
    favourites: "I preferiti",
    clients: "Clienti",
    login: "Accesso",
    logout: "Disconnessione",
    nothing: "No",
    nothingToShow: "Nulla da visualizzare",
    myProfile: "Il mio profilo",
    replacePassword: "Sostituire la password",
    replacePasswordLabel_1: "Password attuale",
    replacePasswordLabel_2: "Nuova password",
    replacePasswordLabel_3: "Ripetere la nuova password",
    advertisement: "Iscrivetevi alla nostra newsletter e rimanete informati su tutte le nostre novità e le ultime uscite.",
    advertisementCheckBox: "Ho letto e accetto la politica",
    inProgressStatus: "In corso",
    waitingStatus: "In attesa",
    sendStatus: "Inviato a",
    finishStatus: "Finito",
    draftStatus: "Bozza",
    canceledStatus: "Annullato",
    retrasedStatus: "In ritardo",
    orderRefecence: "Riferimento ordine",
    orderReferenceHeader: "Rif. ordine",
    buyDateAtHeader: "Data di acquisto",
    stateHeader: "Stato",
    invoiceNumber: "Numero di fattura",
    invoiceNumberHeader: "Numero di fattura",
    notFoundInvoices: "Non sono state trovate fatture.",
    newClient: "Nuovo cliente",
    createClient: "Creare un cliente",
    deactivatedClients: "Clienti disattivati",
    noAvaliableClients: "Nessun cliente disponibile",
    clientInfo: "Informazioni per i clienti",
    clientFile: "Scheda cliente",
    createAddress: "Creare l'indirizzo di spedizione",
    newAddress: "Nuovo indirizzo di spedizione",
    newDeliveryAddress: "Nuovo indirizzo di spedizione",
    sendAddress: "Indirizzo di spedizione",
    logoutWarning: "Se si continua, si viene disconnessi.",
    searchTip_1: "Insoddisfatti dei risultati?",
    searchTip_2: "Ricerca con una nuova parola",
    notFoundProducts: "Nessun prodotto trovato.",
    estimatedStockDate: "Data di disponibilità approssimativa",
    boxQuantity: "Unità per scatola",
    minimumBuyQuantity: "Unità minime di vendita",
    downloadFileProduct: "Scarica la scheda prodotto",
    noAvaliableProducts: "Nessun prodotto disponibile.",
    expandCart: "Cassa",
    emptyCartMessage: "Non ci sono prodotti nel carrello.",
    footerLeftInfo: "Domande? Il team di Perfect è qui per aiutarvi.",
    footerRightInfo: "Se avete dubbi o domande tecniche su uno dei nostri prodotti, scriveteci e un nostro tecnico o rappresentante commerciale vi contatterà.",
    legalWarn: "Avviso legale",
    privacityPolitics: "Informativa sulla privacy",
    cookiesPolitics: "Informativa sui cookie",
    checkout: "Effettuare un ordine",
    deliveryAddress: "Indirizzo di consegna",
    orderSummary: "Riepilogo dell'ordine",
    backCart: "Torna al carrello",
    noAvaliableOrders: "Nessun ordine disponibile",
    taxBase: "Base imponibile",
    taxBaseHeader: "Imponibile B.",
    pvpTaxBaseHeader: "Imponibile B. PVP",
    commerciaRate: "P.V.P",
    noProductsInCart: "È necessario aggiungere almeno un prodotto per continuare",
    downloadHere: "Scarica qui",
    slogan_1: "Perfect Beauty è sinonimo di...",
    slogan_2: "\Design, innovazione, tecnologia e passione.",
    intro_1: "In Perfect Beauty continuiamo a evolverci e a investire per raggiungere la massima qualità del prodotto, l'avanguardia e la tecnologia, per questo abbiamo optato per la creazione di un team di R & S al fine di raggiungere la massima qualità, efficienza e operatività in ciascuno dei nuovi prodotti che portiamo sul mercato. Un altro dei pilastri di Perfect Beauty è il Controllo Qualità, attraverso il quale passa ogni nostro prodotto e le parti che lo compongono dandoci un'affidabilità verso i nostri clienti superiore a quella di qualsiasi marca elettrica. Con il nostro Servizio Tecnico assicuriamo il rispetto di tutte le garanzie.",
    intro_2: "In breve, un gruppo di persone dedicate a rispondere, informare e risolvere qualsiasi domanda o suggerimento dei nostri clienti.",
    newsletter: "Iscrivetevi alla nostra newsletter e rimanete informati su tutte le nostre novità e le ultime uscite.",
    watchMap: "Vedi mappa",
    cataloguePDF: "Catalogo PDF",
    companyIntro_1: "Prodotti utilizzati dai professionisti dei più importanti saloni di bellezza e parrucchieri. Continuiamo ad ampliare le possibilità e ad aprire nuovi orizzonti per i professionisti dell'acconciatura e della bellezza. Sempre aggiornati, con i prodotti più recenti e innovativi per coprire qualsiasi esigenza del settore.",
    companyIntro_2: "Perfect Beauty SL, un'azienda fondata da tre professionisti con oltre 30 anni di esperienza nel settore dell'acconciatura e della bellezza con la missione di fornire al canale professionale prodotti di qualità e innovativi. Un'azienda che si concentra sulla fornitura del miglior servizio a un prezzo competitivo. Per questo disponiamo di un team di 25 professionisti, distribuiti in diversi reparti: servizio clienti, vendite, controllo qualità, servizio tecnico, R+S+I., marketing e amministrazione, con 600 m2 di uffici e un magazzino di 7000 m2 di superficie.",
    companyIntro_3: "Il nostro obiettivo è fornire ai professionisti un'ampia gamma di prodotti per facilitare il loro lavoro nei settori dell'acconciatura, della barberia e della bellezza. Offriamo ai nostri clienti un catalogo con oltre 3.000 referenze, che viene aggiornato due volte l'anno per essere fedeli alla missione della nostra azienda di essere all'avanguardia nel settore con prodotti innovativi.",
    companyIntro_4: "Il controllo della qualità come elemento di differenziazione ci rende un punto di riferimento sul mercato. Grazie alle risorse destinate dall'azienda al controllo del prodotto sia all'origine che a destinazione, siamo un'azienda di garanzia. Il nostro servizio post-vendita si aggiunge a questo impegno verso i clienti soddisfatti. Il nostro reparto di ricerca e sviluppo ci garantisce l'innovazione nel design, nei componenti e nei nuovi prodotti. Un team dedicato a mantenere il marchio Perfect Beauty come azienda dinamica che fornisce soluzioni ai parrucchieri, barbieri ed estetisti professionisti.",
    companyIntro_5: "La direzione di Perfect Beauty è consapevole dell'importanza del contributo di tutti, aziende, istituzioni, amministrazioni pubbliche e singoli individui, nella promozione di pratiche responsabili che rispettino l'ambiente e permettano un chiaro progresso della società verso un mondo migliore; per questo ha imposto l'integrazione di linee guida ambientali nella propria strategia aziendale. Queste linee guida sono definite nella Politica ambientale, che si basa su due principi: il rispetto di tutte le normative ambientali applicabili e il miglioramento continuo delle attività per la tutela dell'ambiente. Con entrambi i principi, si impegna a prevenire, proteggere e conservare l'ambiente.",
    companyIntro_6: "Per attuare questi principi, il management di Perfect Beauty intende: - Adottare le misure necessarie per prevenire l'inquinamento e, ove ciò non sia possibile, per ridurre al minimo le emissioni inquinanti. - Ridurre sistematicamente i rifiuti, riciclandoli e riutilizzandoli ove possibile, e fare un uso efficiente delle risorse naturali, delle materie prime e dell'energia. - Applicare le misure necessarie per garantire la conformità alle normative ambientali applicabili alle nostre attività nell'ambito locale, nazionale e globale in cui l'azienda opera. - Diffondere la politica ambientale ai dipendenti, ai fornitori e alle altre parti interessate. - Formare e sensibilizzare i dipendenti affinché svolgano il loro lavoro quotidiano nel rispetto della politica ambientale. - Stabilire procedure per il riesame periodico della conformità alla politica ambientale e applicare misure correttive in caso di mancato rispetto. - Realizzare un processo di miglioramento continuo delle nostre prestazioni ambientali.",
    corporativeVideo: "Video aziendale.",
    noAddedProducts: "Non ci sono prodotti da acquistare.",
    favouriteAdded: "Il prodotto è stato contrassegnato come preferito.",
    username: "Nome utente",
    dear: "Caro",
    orderSuccesfullySended: "L'ordine è stato inviato con successo.",
    orderSendedMessageQuery_1: "Riceverete un'e-mail con il riepilogo dell'ordine.",
    thanks: "Grazie mille",
    commercialDepartament: "Reparto vendite di Perfect Beauty.",
    seeOrders: "Visualizza gli ordini",
    clearFilters: "Pulire i filtri",
    inStock: "In magazzino",
    noStock: "No stock",
    filterByProduct: "Filtrare per prodotto",
    filterByDate: "Filtrare per data",
    filterByStatus: "Filtrare per stato",
    filterByClient: "Filtrare per cliente",
    fiscalName: "Nome fiscale",

    errorUnlogging: "Si è verificato un errore durante il tentativo di disconnessione.",
    successLogin: "Accesso effettuato con successo.",
    myCompany: "La Mia Azienda",
    goHeadquarterCheckout: "Procedi con l'ordine alla Sede",
    headquarter: "Sede",
    headquarters: "Sedi",
}
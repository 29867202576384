










































import {Component, Vue, Watch} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import DrawerModule from "@/store/DrawerModule"
import ProductCategoryService from "@/services/ProductCategoryService"
import ProductCategory from "@/models/ProductCategory"
import LangModule from "@/store/LangModule"
import ProductCategoryTool from "@/services/tool/ProductCategoryTool"
import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"


@Component({ components: { CategoryTreeviewComponent } })
export default class DrawerComponent extends Vue {

  drawerModule:DrawerModule = getModule(DrawerModule)
  lang: any = getModule(LangModule).lang
	categoryTreeviewModule = getModule(CategoryTreeviewModule)

	get productCategories() { return this.categoryTreeviewModule.productCategories }

	get selectedCategoryIds() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategoryIds(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }

	get drawer() { return this.drawerModule.drawer }
	set drawer(value: boolean) { this.drawerModule.setDrawer(value) }

  loading: boolean = false

  buttons = [
    { title: this.lang.home, url: "/", id: 1 },
    { title: this.lang.products, url: "/products", id: 2 },
    { title: this.lang.novelties, url: "/news", id: 3 },
    { title: this.lang.videos, url: "/video", id: 4 },
    { title: this.lang.contact, url: "/contact", id: 5 },
    { title: this.lang.company, url: "/company", id: 6 }
  ]

	redirectToSearch() {
		this.$router.push({ path: '/products' })
	}

  @Watch('selectedCategories')
	onSelectedCategoriesChanged() {
    //this.$router.push({ path:'/products', query: { categories: ProductCategoryTool.formatCategoryIds(this.selectedCategories) } }).catch(() => {})
	}
}

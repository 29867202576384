import {getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store/index";
import Product from "@/models/Product";
import ConstantTool from "@/services/tool/ConstantTool";
import SessionModule from "@/store/SessionModule";
import axios from "axios";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";

@Module({dynamic: true, store, name: "FavouritesModule"})
export default class FavouritesModule extends VuexModule {

    favoriteProducts: Product[] = []

    @Mutation
    async fetchFavoriteProducts() {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/users/${getModule(SessionModule).session.user.id}/favorite-products`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            this.favoriteProducts.splice(0, this.favoriteProducts.length)
            list.forEach(p => this.favoriteProducts.push(p))
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los productos favoritos.")
        }
    }

}
import {Vue} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"

export default class PasswordService {

	static async requestRecovery(component: Vue, email: string) {
		(<any>component).loading = true
		let formData: FormData = new FormData()
		formData.set("email", email)
		try {
			await component.axios.post(ConstantTool.BASE_URL + "/public/password-recovery", formData)
			getModule(SnackbarModule).makeToast("Su solicitud ha sido procesada, por favor revise su correo.");
		} catch (error) {
			getModule(SnackbarModule).makeToast("Ha ocurrido un error con su solicitud.")
		} finally {
			(<any>component).loading = false
		}
	}

	static async passwordRecovery(component: Vue, token: string, newPassword: string) {
		(<any>component).loading = true
		let formData: FormData = new FormData()
		formData.set("token", token)
		formData.set("newPassword", newPassword)
		try {
			await component.axios.patch(ConstantTool.BASE_URL + "/public/password-recovery", formData)
			getModule(SnackbarModule).makeToast("¡Su contraseña ha sido exitosamente actualizada!")
			component.$router.push("/login")
		} catch (error) {
			getModule(SnackbarModule).makeToast("Ha ocurrido un error con su solicitud.")
		} finally {
			(<any>component).loading = false
		}
	}

	

}

import {JsonObject, JsonProperty} from "json2typescript";
import ProductFeatureType from "@/models/ProductFeatureType";

@JsonObject("ProductFeature")
export default class ProductFeature {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("productFeatureType", ProductFeatureType, true)
    productFeatureType?: ProductFeatureType = undefined
    @JsonProperty("value", String, true)
    value?: string = undefined

}

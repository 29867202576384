import {JsonObject, JsonProperty} from "json2typescript";
import ProductCategory from "@/models/ProductCategory";
import Video from "@/models/Video";
import Document from "@/models/Document";
import ProductFeature from "@/models/ProductFeature";
import ProductBarcode from "@/models/ProductBarcode";
import VatType from "@/models/VatType";
import Rate from "@/models/Rate";
import Brand from "@/models/Brand";

@JsonObject("Product")
export default class Product {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("content", String, true)
    content?: string = undefined
    @JsonProperty("observations", String, true)
    observations?: string = undefined
    @JsonProperty("price", Number, true)
    price?: number = undefined
    @JsonProperty("averageCost", Number, true)
    averageCost?: number = undefined
    @JsonProperty("unitsPerBox", Number, true)
    unitsPerBox?: number = undefined
    @JsonProperty("commercialRate", Number, true)
    commercialRate?: number = undefined
    @JsonProperty("vatPercentage", Number, true)
    vatPercentage?: number = undefined
    @JsonProperty("code", String, true)
    code?: string = undefined

    @JsonProperty("featured", Boolean, true)
    featured?: boolean = undefined
    @JsonProperty("showWeb", Boolean, true)
    showWeb?: boolean = undefined

    quantity: number = 0

    @JsonProperty("productCategories", [ProductCategory], true)
    productCategories?: ProductCategory[] = undefined
    @JsonProperty("video", Video, true)
    video?: Video = undefined
    @JsonProperty("image", Document, true)
    image?: Document = undefined
    @JsonProperty("document", Document, true)
    document?: Document = undefined
    @JsonProperty("brand", Brand, true)
    brand?: Brand = undefined
    @JsonProperty("productFeatures", [ProductFeature], true)
    productFeatures?: ProductFeature[] = undefined
    @JsonProperty("productBarcodes", [ProductBarcode], true)
    productBarcodes?: ProductBarcode[] = undefined
    @JsonProperty("vatType", VatType, true)
    vatType?: VatType = undefined
    @JsonProperty("rates", [Rate], true)
    rates?: Rate[] = undefined

}













































































































import { Component, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import DrawerModule from "@/store/DrawerModule"
import CartMenuComponent from "@/components/CartMenuComponent.vue"
import CountryFlag from 'vue-country-flag'
import CartModule from "@/store/CartModule"
import StoredProduct from "@/models/StoredProduct"
import SessionModule from "@/store/SessionModule"
import LoginService from "@/services/LoginService"
import LangModule from "@/store/LangModule"
import Language from "@/models/Language"
import CartIcon from "@/assets/custom_icons/cart.svg"

@Component({ components: { CartMenuComponent, CountryFlag, CartIcon } })
export default class DesktopHeaderComponent extends Vue {

  langModule: any = getModule(LangModule)
  logged: boolean = true
  showMenu: boolean = false
  showMiniCart: boolean = false
  drawerModule = getModule(DrawerModule)
  cartModule: CartModule = getModule(CartModule)
  sessionModule: SessionModule = getModule(SessionModule)
  get token() {
    return this.sessionModule.session.token
  }

  get isLoged(): boolean {
    return LoginService.isLoged()
  }

  get cart() {
    return this.cartModule.cart
  }

  drawerToggle(){ this.drawerModule.toggle() }

  setLanguage(language: Language) {
    this.langModule.setLanguage(language)
    location.reload()
  }

  redirection() {
    if (this.isLoged) {
      if (this.$route.name != 'Cart') {
        this.showMiniCart = !this.showMiniCart
      }
    } else {
      this.$router.push('/login').catch(() => {})
    }

  }

  buttons = [
      { title: "HOME", url: "/" },
      {
          title: "PRODUCTOS",
          url: "/products"
      },
      { title: "NOVEDADES", url: "/news" },
      { title: "VIDEOS", url: "/video" },
      { title: "CONTACTO", url: "/#" },
      { title: "EMPRESA", url: "/company" }
  ]

  @Watch('cart')
  onCartChanged(val: StoredProduct[], oldVal: StoredProduct[]) {
    if (val.length > oldVal.length) {
      this.showMiniCart = true
    }
  }

  @Watch('$vuetify.breakpoint.name')
  breakpointChanged() {
    console.log(this.$vuetify.breakpoint.name)
  }
}


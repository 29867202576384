import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes:{
            light: {
                primary: '#666666',
                secondary: '#535353',
                accent: '#454545',
                error: '#d9534f',
                info: '#3da9de',
                success: '#F1EB87',
                warning: '#f0ad4e',
                greyPrimary: '#F6F7F6',
                greySecondary: '#E6E8E7',
                greyTertiary: '#AFB3B3',

                primaryS: '#EEF0EE',
                secondaryS: '#424242',
                accentS: '#82B1FF',
                errorS: '#FF5252',
                infoS: '#2196F3',
                successS: '#F1EB87',
                warningS: '#FFC107',

                "pb-success": '#F5EB84',
                "pb-grey": '#DDDDDD',
                "pb-grey-darken-1": '#B4B4B5',
                "pb-grey-darken-2": '#8B8D8D',
                "pb-grey-darken-3": '#686867',
                "pb-grey-darken-4": '#545454',

                "pb-grey-lighten-1": '#EDEDED',
                "pb-grey-lighten-2": '#F8F8F8',
                "pb-barberia": '#75A9A6',
                "pb-estetica": '#D089AC',
                "pb-peluqueria": '#DCD17E',

            },
            dark: {
                primary: '#666666',
                secondary: '#535353',
                accent: '#a49200',
                error: '#d9534f',
                info: '#3da9de',
                success: '#00b879',
                warning: '#f0ad4e'
            }
        }
    }
});
import ConstantTool from "@/services/tool/ConstantTool";
import LoginView from "@/views/LoginView.vue";
import SnackbarModule from "@/store/SnackbarModule";
import LoginResponse from "@/models/responses/LoginRespose";
import SessionModule from "@/store/SessionModule";
import NavbarComponent from "@/components/NavbarComponent.vue";
import {getModule} from "vuex-module-decorators";
import JsonTool from "@/services/tool/JsonTool";
import User from "@/models/User";
import CartModule from "@/store/CartModule";
import {AxiosError, AxiosResponse} from "axios";
import LangModule from "@/store/LangModule";

export default class LoginService {

    private static langModule: LangModule = getModule(LangModule)

    static async checkSession(component: Vue) {
        try {
            let response = await component.axios.get(ConstantTool.BASE_URL + '/api/oauth/check', {
                headers: {Authorization: getModule(SessionModule).session.token}
            }).then(() => {
                console.log("Logged successfully")
            })
        } catch (error) {
            console.log(error as AxiosResponse)
            if ((error as AxiosError).response?.status == 401) {
                console.log("Unlogging")
                await this.logout(component)
            }
        }
    }

    static postLogin(component: Vue, email: string, password: string, snackbarModule: SnackbarModule, sessionModule: SessionModule) {
        // @ts-ignore
        component.progress = true

        let formData: FormData = new FormData()
        formData.set("username", email)
        formData.set("password", password)

        component.axios.post(ConstantTool.BASE_URL + "/public/oauth/login", formData)
            .then(response => {
                let loginResponse: LoginResponse = JsonTool.jsonConvert.deserializeObject(response.data, LoginResponse)
                sessionModule.session.token = "Bearer " + loginResponse.oauth2AccessToken!.accessToken
                sessionModule.session.user = loginResponse.user!
                sessionModule.session.authorities = loginResponse.authorities!
                if (component.$route.query.redirect) {
                    component.$router.push({path: component.$route.query.redirect as string}).catch(() => {})
                } else {
                    component.$router.push({path: "/"}).catch(() => {})
                }
                snackbarModule.makeToast(this.langModule.lang.successLogin)
            })
            .catch(error => {
                snackbarModule.makeToast("Usuario o contraseña no válidos")
            })
            .finally(() => {
                // @ts-ignore
                component.progress = false
                // @ts-ignore
                component.loginButtonReset++
            })
    }

    static patchPassword(component: Vue, password: string, newPassword: string) {
        // @ts-ignore
        component.loading = true
        let user: User = getModule(SessionModule).session.user
        try {
            component.axios.patch(ConstantTool.BASE_URL + `/api/users/${user.id}/change-password`, {
                params: { password, newPassword },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se ha podido actualizar la contraseña.")
        } finally {
            // @ts-ignore
            component.loading = false
        }

    }

    static async logout(component: NavbarComponent) {
        try {
            await component.axios.post(ConstantTool.BASE_URL + "/api/oauth/logout", null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let sessionModule: SessionModule = getModule(SessionModule)
            sessionModule.session.token = ""
            sessionModule.saveSession()
            //getModule(CartModule).clearCart()
            await component.$router.push("/login")
        } catch (e) {
            getModule(SnackbarModule).makeToast(this.langModule.lang.errorUnlogging)
        }

    }

    static isLoged() {
        if (getModule(SessionModule).session.token == null) {
            return false
        } else {
            return !(getModule(SessionModule).session.token.trim() == "")
        }
    }

}

import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";

export enum ProductFeatureTypeEnum {
    INTEGER = "INTEGER",
    FLOAT = "FLOAT",
    STRING = "STRING",
    BOOLEAN = "BOOLEAN",
    VALUES = "VALUES",
}

@JsonConverter
export class TypeConverter implements JsonCustomConvert<ProductFeatureTypeEnum> {
    deserialize(data: any): ProductFeatureTypeEnum {
        let res = (<any>ProductFeatureTypeEnum)[data]
        if (res == undefined) console.error("Error parsing enum", data)
        return res
    }
    serialize(data: ProductFeatureTypeEnum): any {
        return data.toString()
    }
}

@JsonObject("ProductFeatureType")
export default class ProductFeatureType {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("type", TypeConverter, true)
    type?: ProductFeatureTypeEnum = undefined
    @JsonProperty("magnitude", String, true)
    magnitude?: string = undefined

}

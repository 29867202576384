import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";

export enum EntityType {
    PRODUCT = "PRODUCT",
    USER = "USER"
}

@JsonConverter
class NameConverter implements JsonCustomConvert<EntityType> {
    deserialize(data: any): EntityType {
        return (<any>EntityType)[data]
    }
    serialize(data: EntityType): any {
        return data.toString()
    }
}

@JsonObject("VatType")
export default class VatType {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("title", String, true)
    title?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("entityType", NameConverter, true)
    entityType?: NameConverter = undefined

}





















































































































import { Component, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import DrawerModule from "@/store/DrawerModule"
import CartMenuComponent from "@/components/CartMenuComponent.vue"
import CountryFlag from 'vue-country-flag'
import CartModule from "@/store/CartModule"
import StoredProduct from "@/models/StoredProduct"
import SessionModule from "@/store/SessionModule"
import LoginService from "@/services/LoginService"
import LangModule from "@/store/LangModule"
import Language from "@/models/Language"
import PerfectBeautyNavIcon from "@/assets/custom_icons/perfect_beauty_nav.svg"
import CartIcon from "@/assets/custom_icons/cart.svg"
import UserIcon from "@/assets/custom_icons/user.svg"
import SearchIcon from "@/assets/custom_icons/magnify.svg"
import StarIcon from "@/assets/custom_icons/favourite.svg"
import LangMenuComponent from './LangMenuComponent.vue'
import LoginMenuComponent from './LoginMenuComponent.vue'
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import RecoverPasswordDialog from "@/components/dialogs/RecoverPasswordDialog.vue";
import FavouritesModule from "@/store/FavouritesModule";


@Component({ components: {
	CartMenuComponent, CountryFlag, PerfectBeautyNavIcon, LangMenuComponent,
		LoginMenuComponent, CartIcon, UserIcon, SearchIcon, StarIcon,
		RecoverPasswordDialog,
} })
export default class HeaderComponent extends Vue {

	favorites: any = getModule(FavouritesModule).favoriteProducts
	lang: any = getModule(LangModule).lang
	dialog: boolean = false
	recoveryDialog: boolean = false

	sessionModule: SessionModule = getModule(SessionModule)
	cartModule: CartModule = getModule(CartModule)
	langModule: any = getModule(LangModule)

	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }

	loginMenu: boolean = false
  get showMiniCart() { return this.cartModule.showMiniCart }
  set showMiniCart(value: boolean) { this.cartModule.setShowMiniCart(value) }
	drawerModule = getModule(DrawerModule)

  get token() { return this.sessionModule.session.token }
  get isLoged(): boolean { return LoginService.isLoged() }
  get cart() { return this.cartModule.cart }

  drawerToggle(){ this.drawerModule.toggle() }

  setLanguage(language: Language) {
    this.langModule.setLanguage(language)
    location.reload()
  }

	redirection() {
		if (this.isLoged) {
			if (this.$route.name != 'Cart') { this.showMiniCart = !this.showMiniCart }
		} else { this.$router.push('/login').catch(() => {}) }

	}

	onSuccessRequestedRecovery() {
		this.dialog = false
		this.recoveryDialog = true
	}

  @Watch('cart')
  onCartChanged(val: StoredProduct[], oldVal: StoredProduct[]) {
    if (val.length > oldVal.length) {
      this.showMiniCart = true
    }
  }
}


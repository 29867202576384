








































import {Component, Ref, Vue, Watch} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"

import CloseIcon from "@/assets/custom_icons/close.svg"
import StringTool from "@/services/tool/StringTool";
import LoginService from "@/services/LoginService";
import SnackbarModule from "@/store/SnackbarModule";
import SessionModule from "@/store/SessionModule";
import RulesTool from "@/services/tool/RulesTool";


@Component({ components: { CloseIcon } })
export default class LoginMenuComponent extends Vue {

	@Ref() readonly loginForm!: HTMLFormElement

	langModule: any = getModule(LangModule)
	sessionModule: SessionModule = getModule(SessionModule)

	rules = RulesTool

	lang = this.langModule.lang
	loading: boolean = false

	email: string = ""
	password: string = ""

	basicRules = [(input: string) => (input ? true : this.lang.emptyLabel)]
	emailRules = [(input: string) => StringTool.validateEmail(input) ? true : this.lang.invalidEmail]

	login() {
		if (this.loginForm.validate()) {
			LoginService.postLogin(this, this.email, this.password, getModule(SnackbarModule), this.sessionModule)
		}
	}

}


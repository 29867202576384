//@ts-ignore
import VueLineClamp from 'vue-line-clamp'
// @ts-ignore
import LazyTube from "vue-lazytube"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AxiosConfig from "@/config/AxiosConfig"
import vuetify from './config/vuetify'
import VueDebounce from 'vue-debounce'
import CountryFlag from 'vue-country-flag'
import LangModule from './store/LangModule'
import { getModule } from 'vuex-module-decorators'
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"
import "@/assets/custom-styles.css"

Vue.config.productionTip = false

Vue.use(CountryFlag)
Vue.use(VueLineClamp)
Vue.use(VueDebounce)
Vue.use(LazyTube)

let vue: Vue

AxiosConfig.init(vue!)
getModule(LangModule).loadLanguage()
getModule(CategoryTreeviewModule).refreshProductCategories()

vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("ProductBarcode")
export default class ProductBarcode {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("barcode", String, true)
    barcode?: string = undefined

}

import BreadcrumbsModule from '@/store/BreadcrumsModule'
import SessionModule from '@/store/SessionModule'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { getModule } from 'vuex-module-decorators'
import LoginService from "@/services/LoginService";
import ConstantTool from '@/services/tool/ConstantTool'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'Home', component: () => import("../views/HomeView.vue"), meta: { guest: true } },
  { path: '/legal-warning', name: 'Legal Warning', component: () => import("../views/LegalWarningView.vue"), meta: { guest: true } },
  { path: '/privacy-policies', name: 'Pricacy Policies', component: () => import("../views/PrivacyPoliciesView.vue"), meta: { guest: true } },
  { path: '/cookies-policies', name: 'Cookies Policies', component: () => import("../views/CookiePoliciesView.vue"), meta: { guest: true } },
  { path: '/password-recovery', name: 'Password Recovery', component: () => import("../views/PasswordRecoveryView.vue"), meta: { guest: true } },
  { path: '/profile/settings/:tabName?', name: 'Settings', component: () => import("../views/SettingsView.vue"), meta: { guest: false } },
  { path: '/video/:id?', name: 'Video', component: () => import('../views/VideoView.vue'), meta: { guest: true } },
  { path: '/news', name: 'News', component: () => import('../views/NewsView.vue'), meta: { guest: true } },
  { path: '/contact', name: 'Contact', component: () => import('../views/ContactView.vue'), meta: { guest: true } },
  { path: '/company', name: 'Company', component: () => import('../views/CompanyView.vue'), meta: { guest: true } },
  { path: '/product/:id', name: 'Product', component: () => import('../views/ProductView.vue'), meta: { guest: true } },
  { path: '/products', name: 'Products', component: () => import('../views/ProductsView.vue'), meta: { guest: true } },
  { path: '/search', name: 'Search', component: () => import('../views/SearchProductView.vue'), meta: { guest: true } },
  { path: '/login', name: 'Login', component: () => import('../views/LoginView.vue'), meta: { guest: true } },
  { path: '/dashboard', name: 'Dashboard', component: () => import('../views/DashboardView.vue'), meta: { guest: true } },
  { path: '/cart', name: 'Cart', component: () => import('../views/CartView.vue'), meta: { guest: false } },
  { path: '/address', name: 'Address', component: () => import('../views/AddressView.vue'), meta: { guest: false } },
  { path: '/checkout', name: 'Checkout', component: () => import('../views/CheckoutView.vue'), meta: { guest: false } },
  { path: '/checkout-headquarter', name: 'Checkout headquarter order', component: () => import('../views/CheckoutHeadquarterOrder.vue'), meta: { guest: false } },

  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to, from, next) => {
  const { authorize } = to.meta
  const breadcrumbsModule = getModule(BreadcrumbsModule)

  if (to.name != "home") {
    breadcrumbsModule.addItem({text: to.name, disabled: false, href: to.path})
  }

  let sessionModule: SessionModule = getModule(SessionModule)
  if (sessionModule.session && sessionModule.session.token) {
    sessionModule.saveSession()
  }
  sessionModule.loadSession()
  let authorities = sessionModule.session.authorities
  
  if (!LoginService.isLoged()) {
    if (!to.meta.guest || (ConstantTool.PERSISTENT_LOGIN && to.name != "Login")) {
      return next({path:'/login'})
    }
  }

  /*

  if ((!sessionModule.session.token) && (to.path != "/") && (to.path != "/products") && (to.path != "/product/*") && (to.path != "/search") && (to.path != "/video") && (to.path != "/checkout") && (to.path != "/news") && (to.path != "/cart") && (to.path != "/contact") && (to.path != "/product") && (to.path != "/password-recovery") && (to.path != "/company")) {
    if (to.path != "/login") {
      return next({path: "/login", query: {redirect: to.path}})
    }
  } else if (to.path == "/login") {
    return next({path: "/"})
  } else if (to.path != "/" && authorize) {
    for (let authority of authorities) {
      if (authorize.includes(authority.name)) {
        return next()
      }
    }
    return next({path: "/"})
  }

  */
  next()
})

export default router

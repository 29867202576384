import {Component, Vue} from "vue-property-decorator";
import LangModule from "@/store/LangModule";
import {getModule} from "vuex-module-decorators";
import Language from "@/models/Language";

@Component
export default class LangMixin extends Vue {

    get lang() { return getModule(LangModule).lang }

    refreshLang() {
        let languageObject = localStorage.getItem(Language.KEY)
        // alert(languageObject)
        getModule(LangModule).loadLanguage()
    }

}
import {JsonObject, JsonProperty} from "json2typescript";
import Document from "./Document"

@JsonObject("ProductCategory")
export default class ProductCategory {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("code", String, true)
    code?: string = undefined
    @JsonProperty("parent", ProductCategory, true)
    parent?: ProductCategory = undefined
    @JsonProperty("image", Document, true)
    image?: Document = undefined
    @JsonProperty("hasChildren", Boolean, true)
    hasChildren?: boolean = undefined
    @JsonProperty("childrens", ProductCategory, true)
    children?: ProductCategory[] = []
}

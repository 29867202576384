import store from "@/store"
import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import StoredProduct from "@/models/StoredProduct"
import Product from "@/models/Product";

@Module({dynamic: true, store, name: "CartModule"})
export default class CartModule extends VuexModule {

    showMiniCart: boolean = false
    cart: StoredProduct[] = []

    @Mutation
    setCart(cart: StoredProduct[]) {
        this.cart = cart
    }

    @Mutation
    setShowMiniCart(value: boolean) {
        this.showMiniCart = value
    }

    @Mutation
    addProduct(product: Product) {
      this.cart.push({ productId: product.id, quantity: product.quantity })
      getModule(SnackbarModule).makeToast("Se ha añadido su producto al carrito!")
    }

    @Mutation
    spliceProduct(productIndex: number) {
      this.cart.splice(productIndex, 1)
      getModule(SnackbarModule).makeToast("Se ha eliminado el producto del carrito!")
    }

    @Action
    saveCart() {
        try {
            let object = JSON.stringify(this.cart)
            localStorage.setItem("cart", object)
        } catch (e) {
            console.log("Ocurrio un error guardando...")
            localStorage.removeItem("cart")
        }
    }

    @Action
    loadCart() {
        try {
            let cart = localStorage.getItem("cart")
            if (cart != null) {
                this.setCart(JSON.parse(cart))
            } 
        } catch (e) {
            console.log("Ocurrio un error guardando...")
            localStorage.removeItem("cart")
        }
    }

    @Action
    clearCart() {
        try {
            this.setCart([])
            this.saveCart()
        } catch (e) {
            console.log("Ocurrio un error limpiando el carrito...")
        }
    }
}